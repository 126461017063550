// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-album-[id]-js": () => import("./../../../src/pages/album/[id].js" /* webpackChunkName: "component---src-pages-album-[id]-js" */),
  "component---src-pages-event-[id]-js": () => import("./../../../src/pages/event/[id].js" /* webpackChunkName: "component---src-pages-event-[id]-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-prismic-page-uid-js": () => import("./../../../src/pages/{PrismicPage.uid}.js" /* webpackChunkName: "component---src-pages-prismic-page-uid-js" */)
}

